<template>
    <CCol sm="12">
        <CInputGroup>
            <CNInput label="Tax ID / EIN" :model-value="modelValue" v-mask="['##-#######']" max-length="255"
                     :invalid="!!error" @input="$emit('update:modelValue', $event.target.value)"
                     @blur="$emit('blurValid', 'tid', $event)"/>
        </CInputGroup>

        <CFormText v-if="error" :style="`color: ${error && 'red'}`">{{ error }}</CFormText>
    </CCol>
</template>

<script>
import {mask} from 'vue-the-mask'
import axios from 'axios'
import CNInput from "@/components/ui/CNInput/CNInput.vue";

export default {
    name: 'TaxId',
    components: {CNInput},
    directives: {mask},
    inject: ['toast'],
    props: {
        modelValue: {},
        companyId: {type: Number, default: () => null},
        verified: {type: Boolean, default: () => false},
        error: {type: String, default: () => ''},
    },
    emits: ['update:modelValue', 'handleChangeField', 'blurValid'],
    data() {
        return {
            url: 'https://api.taxid.pro/validate',
            loading: false,
        }
    },
    methods: {
        async validateTax() {
            this.loading = true
            await axios
                .get(this.url, {
                    params: {
                        key: process.env.VUE_APP_TAX_PRO_KEY,
                        country: 'us',
                        tin: this.modelValue,
                    },
                    headers: {
                        'content-type': 'application/json; charset=utf-8',
                    },
                })
                .then((res) => {
                    this.$emit('update:verified', res.data.valid || false)
                    this.$http.setting.verifiedCompanyTaxId(this.companyId, {
                        verified_tid: res.data.valid,
                    })
                    if (!res.data.valid) {
                        this.toast('warning', res.data.message)
                    }
                })
                .catch(() => this.toast('warning', 'Error!'))
                .finally(() => (this.loading = false))
        },
    },
}
</script>

<style scoped></style>
